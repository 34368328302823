import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDoubleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faArrowsAltH,
  faArrowsAltV,
  faAtom,
  faBan,
  faBars,
  faBell,
  faBezierCurve,
  faBook,
  faBookMedical,
  faBookOpen,
  faBookReader,
  faBriefcase,
  faBuilding,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faCalendarWeek,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCashRegister,
  faChartArea,
  faChartLine,
  faChartPie,
  faCheck,
  faCheckDouble,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleNotch,
  faClipboard,
  faClipboardCheck,
  faClipboardList,
  faClock,
  faCloudDownloadAlt,
  faCodeBranch,
  faCog,
  faCogs,
  faCoins,
  faCommentAlt,
  faCommentDollar,
  faCompress,
  faConciergeBell,
  faCopy,
  faCreditCard,
  faCrown,
  faDatabase,
  faDesktop,
  faDiagnoses,
  faDoorOpen,
  faDownload,
  faDumpster,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExchangeAlt,
  faExclamation,
  faExclamationTriangle,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileContract,
  faFileCsv,
  faFileDownload,
  faFileExcel,
  faFileExport,
  faFileImport,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFilm,
  faFilter,
  faFingerprint,
  faFlask,
  faFolderOpen,
  faForward,
  faForwardFast,
  faForwardStep,
  faGraduationCap,
  faHammerBrush,
  faHandHoldingUsd,
  faHandshake,
  faHistory,
  faHollyBerry,
  faHourglassEnd,
  faHourglassHalf,
  faHourglassStart,
  faHouse,
  faIdCard,
  faIdCardAlt,
  faImage,
  faIndustry,
  faInfo,
  faKey,
  faLayerGroup,
  faLevelUpAlt,
  faLifeRing,
  faLightbulb,
  faLightEmergencyOn,
  faLink,
  faList,
  faListUl,
  faLock,
  faLockOpen,
  faMailBulk,
  faMap,
  faMapMarkerAlt,
  faMessagesQuestion,
  faMicrochip,
  faMicroscope,
  faMobileAlt,
  faMoneyBillWaveAlt,
  faNewspaper,
  faObjectGroup,
  faPaperPlane,
  faParachuteBox,
  faPauseCircle,
  faPeopleArrows,
  faPeopleCarry,
  faPhone,
  faPhoneAlt,
  faPhotoVideo,
  faPlay,
  faPlus,
  faPollH,
  faPrint,
  faProjectDiagram,
  faPuzzlePiece,
  faQuestion,
  faQuestionCircle,
  faReceipt,
  faRectangleHistory,
  faRecycle,
  faRedo,
  faRedoAlt,
  faReply,
  faRightLong,
  faRobot,
  faRoute,
  faSave,
  faScrewdriver,
  faSearch,
  faSearchPlus,
  faServer,
  faShareSquare,
  faShieldAlt,
  faShoppingCart,
  faSign,
  faSignature,
  faSignInAlt,
  faSignOutAlt,
  faSlash,
  faSlidersH,
  faSort,
  faSortDown,
  faSortUp,
  faSpellCheck,
  faSpinner,
  faSquareCheck,
  faStar,
  faStepBackward,
  faStop,
  faStopwatch,
  faStream,
  faStroopwafel,
  faSync,
  faTachometerAlt,
  faTasks,
  faTimes,
  faTimesCircle,
  faToolbox,
  faTools,
  faTrash,
  faTrashAlt,
  faTrophy,
  faTruck,
  faTv,
  faUndo,
  faUniversity,
  faUnlockAlt,
  faUser,
  faUserCircle,
  faUserClock,
  faUserCog,
  faUserDoctorMessage,
  faUserEdit,
  faUserFriends,
  faUserGraduate,
  faUserLock,
  faUsers,
  faUsersCog,
  faUserSecret,
  faUserShield,
  faUserTag,
  faUserTie,
  faUserTimes,
  faVectorSquare,
  faVial,
  faVials,
  faVideo,
  faWater,
  faWindowMaximize,
  faWrench,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDoubleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faArrowsAltH,
  faArrowsAltV,
  faAtom,
  faBan,
  faBars,
  faBell,
  faBezierCurve,
  faBook,
  faBookMedical,
  faBookOpen,
  faBookReader,
  faBriefcase,
  faBuilding,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faCalendarWeek,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCashRegister,
  faChartArea,
  faChartLine,
  faChartPie,
  faCheck,
  faCheckDouble,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleNotch,
  faClipboard,
  faClipboardCheck,
  faClipboardList,
  faClock,
  faCloudDownloadAlt,
  faCodeBranch,
  faCog,
  faCogs,
  faCoins,
  faCommentAlt,
  faCommentDollar,
  faCompress,
  faConciergeBell,
  faCopy,
  faCreditCard,
  faCrown,
  faDatabase,
  faDesktop,
  faDiagnoses,
  faDoorOpen,
  faDownload,
  faDumpster,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExchangeAlt,
  faExclamation,
  faExclamationTriangle,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileContract,
  faFileCsv,
  faFileDownload,
  faFileExcel,
  faFileExport,
  faFileImport,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFilm,
  faFilter,
  faFingerprint,
  faFlask,
  faFolderOpen,
  faForward,
  faForwardFast,
  faForwardStep,
  faGraduationCap,
  faHammerBrush,
  faHandHoldingUsd,
  faHandshake,
  faHistory,
  faHollyBerry,
  faHourglassEnd,
  faHourglassHalf,
  faHourglassStart,
  faHouse,
  faIdCard,
  faIdCardAlt,
  faImage,
  faIndustry,
  faInfo,
  faKey,
  faLayerGroup,
  faLevelUpAlt,
  faLifeRing,
  faLightbulb,
  faLightEmergencyOn,
  faLink,
  faList,
  faListUl,
  faLock,
  faLockOpen,
  faMailBulk,
  faMap,
  faMapMarkerAlt,
  faMessagesQuestion,
  faMicrochip,
  faMicroscope,
  faMobileAlt,
  faMoneyBillWaveAlt,
  faNewspaper,
  faObjectGroup,
  faPaperPlane,
  faParachuteBox,
  faPauseCircle,
  faPeopleArrows,
  faPeopleCarry,
  faPhone,
  faPhoneAlt,
  faPhotoVideo,
  faPlay,
  faPlus,
  faPollH,
  faPrint,
  faProjectDiagram,
  faPuzzlePiece,
  faQuestion,
  faQuestionCircle,
  faReceipt,
  faRectangleHistory,
  faRecycle,
  faRedo,
  faRedoAlt,
  faReply,
  faRightLong,
  faRobot,
  faRoute,
  faSave,
  faScrewdriver,
  faSearch,
  faSearchPlus,
  faServer,
  faShareSquare,
  faShieldAlt,
  faShoppingCart,
  faSign,
  faSignature,
  faSignInAlt,
  faSignOutAlt,
  faSlash,
  faSlidersH,
  faSort,
  faSortDown,
  faSortUp,
  faSpellCheck,
  faSpinner,
  faSquareCheck,
  faStar,
  faStepBackward,
  faStop,
  faStopwatch,
  faStream,
  faStroopwafel,
  faSync,
  faTachometerAlt,
  faTasks,
  faTimes,
  faTimesCircle,
  faToolbox,
  faTools,
  faTrash,
  faTrashAlt,
  faTrophy,
  faTruck,
  faTv,
  faUndo,
  faUniversity,
  faUnlockAlt,
  faUser,
  faUserCircle,
  faUserClock,
  faUserCog,
  faUserDoctorMessage,
  faUserEdit,
  faUserFriends,
  faUserGraduate,
  faUserLock,
  faUsers,
  faUsersCog,
  faUserSecret,
  faUserShield,
  faUserTag,
  faUserTie,
  faUserTimes,
  faVectorSquare,
  faVial,
  faVials,
  faVideo,
  faWater,
  faWindowMaximize,
  faWrench,
);
